@import '../Colors.scss';
@import '../Variables.scss';

:export {
    columnDividerWidth: $column-divider-width;
    cellXPadding: $cell-x-padding;
    tableBodyFontFamily: $table-body-font-family;
    tableBodyFontSize: $table-body-font-size;
}

.container {
    height: 100%;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}
.header {
    position: relative;
    font-weight: 500;
    background-color: $header-back-color;
    border-bottom: $table-grid-color $table-grid-width solid;
    white-space: nowrap;
    overflow: hidden;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    min-height: $table-header-height;
    max-height: $table-header-height;
    .columnDivider {
        background-color: $column-divider-color;
        cursor: col-resize;
        display: inline-block;
        vertical-align: top;
        background-clip: content-box;
        padding: $table-header-cell-y-padding 0;
        width: $column-divider-width;
    }
    .columnDivider:hover {
        background-color: $amber-c500;
    }
    .columnDivider.draggedColumnDivider {
        background-color: $amber-c500;
    }
    .fullHeightColDivider {
        background-clip: padding-box !important;
    }
}
.headerCell {
    display: inline-block;
    position: relative;
    background-color: $header-back-color;
    padding: $table-header-cell-y-padding $cell-x-padding;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: hidden;
    vertical-align: top;
    -webkit-tap-highlight-color: transparent;
    transition: background-color 0.3s ease;
}
.draggedHeaderCell {
    cursor: col-resize;
}
.headerCellControl {
    position: absolute;
    color: black;
    top: 0;
    left: 0;
    padding: 0.15em 0.1em;
    transition: opacity 0.3s ease;
}
.controlItem {
    fill: currentColor;
    font-size: 12px;
    width: 12px;
    height: 12px;
    padding: 0 0.1em;
    color: $col-ctrl-icon-off-color;
    transition: color 0.3s ease;
}
.controlItem:hover {
    color: $col-ctrl-icon-hover-color;
}
.controlItemOn {
    color: $col-ctrl-icon-on-color;
}
.controlItemDisabled {
    color: $col-ctrl-icon-disabled-color;
    touch-action: none;
    pointer-events: none;
}
.pager {
    position: absolute;
    background-color: white;
    bottom: 0;
    right: 0;
    border-top: 1px solid $table-grid-color;
    border-left: 1px solid $table-grid-color;
    font-size: 1.2em;
    transition: opacity 0.3s ease;
}
.pagerItem {
    font-size: 12px;
    width: 12px;
    height: 12px;
    fill: currentColor;
    color: $col-ctrl-icon-off-color;
    padding: 0 0.3em;
    transition: color 0.3s ease;
}
.pagerItem:hover {
    color: $col-ctrl-icon-hover-color;
}
.pagerItemDisabled {
    color: $col-ctrl-icon-disabled-color;
    touch-action: none;
    pointer-events: none;
}
.body {
    font-family: $table-body-font-family, monospace;
    font-size: $table-body-font-size;
    font-weight: 400;
    height: 100%;
    overflow: hidden;
    position: relative;
}
.row {
    border-bottom: $table-grid-color $table-grid-width solid;
    z-index: 9999;
    line-height: 1;
    white-space: nowrap;
    overflow: hidden;
    -webkit-tap-highlight-color: transparent;
    .columnDivider {
        cursor: default;
        display: inline-block;
        background-clip: padding-box;
        vertical-align: top;
        padding: $table-body-cell-y-padding 0;
        width: $column-divider-width;
    }
    .fullHeightColDivider {
        background-color: $column-divider-color;
    }
}
.content {
    position: relative;
    touch-action: none;
}
.bodyCell {
    padding: $table-body-cell-y-padding $cell-x-padding;
    line-height: 1;
    text-overflow: ellipsis;
    text-align: left;
    white-space: nowrap;
    overflow-x: hidden;
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    transition: background-color 0.3s ease;
}
.selectedColumn {
    background-color: $selected-column-back-color;
}
.hoveredColumn {
    background-color: $hovered-column-back-color;
}
.hoveredSelectedColumn {
    background-color: $hover-sel-column-back-color;
}
.pinnedColumn {
    background-color: $pinned-column-back-color;
}
.scrollBar {
    position: absolute;
    width: 9px;
    right: 2px;
    bottom: 2px;
    top: 2px;
    border-radius: 5px;
    opacity: 0.5;
    touch-action: none;
    transition: background-color 0.3s ease;
}
.scrollBar:hover {
    background-color: $scrollbar-back-color;
    .scrollThumb {
        background-color: $scrollbar-thumb-back-color;
    }
}
.scrollingBar {
    background-color: $scrollbar-back-color;
    .scrollThumb {
        background-color: $scrollbar-thumb-back-color;
    }
}
.scrollThumb {
    position: relative;
    display: block;
    width: 100%;
    cursor: pointer;
    border-radius: inherit;
    background-color: $grey-c400;
}
.nonSelectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.loader {
    border-left: 3px solid $grey-c100;
    border-right: 3px solid $grey-c100;
    border-bottom: 3px solid $grey-c100;
    border-top: 3px solid $grey-c500;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    animation: spin 0.3s linear infinite;
    margin-left: 50%;
}
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}