// Regex: \$(red|pink|purple|deepPurple|indigo|blue|lightBlue|cyan|teal|green|lightGreen|lime|yellow|amber|orange|deepOrange|brown|grey|blueGrey)-[ac]\d{2,3}

$red-c50: #ffebee;
$red-c100: #ffcdd2;
$red-c200: #ef9a9a;
$red-c300: #e57373;
$red-c400: #ef5350;
$red-c500: #f44336;
$red-c600: #e53935;
$red-c700: #d32f2f;
$red-c800: #c62828;
$red-c900: #b71c1c;
$red-a100: #ff8a80;
$red-a200: #ff5252;
$red-a400: #ff1744;
$red-a700: #d50000;
$pink-c50: #fce4ec;
$pink-c100: #f8bbd0;
$pink-c200: #f48fb1;
$pink-c300: #f06292;
$pink-c400: #ec407a;
$pink-c500: #e91e63;
$pink-c600: #d81b60;
$pink-c700: #c2185b;
$pink-c800: #ad1457;
$pink-c900: #880e4f;
$pink-a100: #ff80ab;
$pink-a200: #ff4081;
$pink-a400: #f50057;
$pink-a700: #c51162;
$purple-c50: #f3e5f5;
$purple-c100: #e1bee7;
$purple-c200: #ce93d8;
$purple-c300: #ba68c8;
$purple-c400: #ab47bc;
$purple-c500: #9c27b0;
$purple-c600: #8e24aa;
$purple-c700: #7b1fa2;
$purple-c800: #6a1b9a;
$purple-c900: #4a148c;
$purple-a100: #ea80fc;
$purple-a200: #e040fb;
$purple-a400: #d500f9;
$purple-a700: #aa00ff;
$deepPurple-c50: #ede7f6;
$deepPurple-c100: #d1c4e9;
$deepPurple-c200: #b39ddb;
$deepPurple-c300: #9575cd;
$deepPurple-c400: #7e57c2;
$deepPurple-c500: #673ab7;
$deepPurple-c600: #5e35b1;
$deepPurple-c700: #512da8;
$deepPurple-c800: #4527a0;
$deepPurple-c900: #311b92;
$deepPurple-a100: #b388ff;
$deepPurple-a200: #7c4dff;
$deepPurple-a400: #651fff;
$deepPurple-a700: #6200ea;
$indigo-c50: #e8eaf6;
$indigo-c100: #c5cae9;
$indigo-c200: #9fa8da;
$indigo-c300: #7986cb;
$indigo-c400: #5c6bc0;
$indigo-c500: #3f51b5;
$indigo-c600: #3949ab;
$indigo-c700: #303f9f;
$indigo-c800: #283593;
$indigo-c900: #1a237e;
$indigo-a100: #8c9eff;
$indigo-a200: #536dfe;
$indigo-a400: #3d5afe;
$indigo-a700: #304ffe;
$blue-c50: #e3f2fd;
$blue-c100: #bbdefb;
$blue-c200: #90caf9;
$blue-c300: #64b5f6;
$blue-c400: #42a5f5;
$blue-c500: #2196f3;
$blue-c600: #1e88e5;
$blue-c700: #1976d2;
$blue-c800: #1565c0;
$blue-c900: #0d47a1;
$blue-a100: #82b1ff;
$blue-a200: #448aff;
$blue-a400: #2979ff;
$blue-a700: #2962ff;
$lightBlue-c50: #e1f5fe;
$lightBlue-c100: #b3e5fc;
$lightBlue-c200: #81d4fa;
$lightBlue-c300: #4fc3f7;
$lightBlue-c400: #29b6f6;
$lightBlue-c500: #03a9f4;
$lightBlue-c600: #039be5;
$lightBlue-c700: #0288d1;
$lightBlue-c800: #0277bd;
$lightBlue-c900: #01579b;
$lightBlue-a100: #80d8ff;
$lightBlue-a200: #40c4ff;
$lightBlue-a400: #00b0ff;
$lightBlue-a700: #0091ea;
$cyan-c50: #e0f7fa;
$cyan-c100: #b2ebf2;
$cyan-c200: #80deea;
$cyan-c300: #4dd0e1;
$cyan-c400: #26c6da;
$cyan-c500: #00bcd4;
$cyan-c600: #00acc1;
$cyan-c700: #0097a7;
$cyan-c800: #00838f;
$cyan-c900: #006064;
$cyan-a100: #84ffff;
$cyan-a200: #18ffff;
$cyan-a400: #00e5ff;
$cyan-a700: #00b8d4;
$teal-c50: #e0f2f1;
$teal-c100: #b2dfdb;
$teal-c200: #80cbc4;
$teal-c300: #4db6ac;
$teal-c400: #26a69a;
$teal-c500: #009688;
$teal-c600: #00897b;
$teal-c700: #00796b;
$teal-c800: #00695c;
$teal-c900: #004d40;
$teal-a100: #a7ffeb;
$teal-a200: #64ffda;
$teal-a400: #1de9b6;
$teal-a700: #00bfa5;
$green-c50: #e8f5e9;
$green-c100: #c8e6c9;
$green-c200: #a5d6a7;
$green-c300: #81c784;
$green-c400: #66bb6a;
$green-c500: #4caf50;
$green-c600: #43a047;
$green-c700: #388e3c;
$green-c800: #2e7d32;
$green-c900: #1b5e20;
$green-a100: #b9f6ca;
$green-a200: #69f0ae;
$green-a400: #00e676;
$green-a700: #00c853;
$lightGreen-c50: #f1f8e9;
$lightGreen-c100: #dcedc8;
$lightGreen-c200: #c5e1a5;
$lightGreen-c300: #aed581;
$lightGreen-c400: #9ccc65;
$lightGreen-c500: #8bc34a;
$lightGreen-c600: #7cb342;
$lightGreen-c700: #689f38;
$lightGreen-c800: #558b2f;
$lightGreen-c900: #33691e;
$lightGreen-a100: #ccff90;
$lightGreen-a200: #b2ff59;
$lightGreen-a400: #76ff03;
$lightGreen-a700: #64dd17;
$lime-c50: #f9fbe7;
$lime-c100: #f0f4c3;
$lime-c200: #e6ee9c;
$lime-c300: #dce775;
$lime-c400: #d4e157;
$lime-c500: #cddc39;
$lime-c600: #c0ca33;
$lime-c700: #afb42b;
$lime-c800: #9e9d24;
$lime-c900: #827717;
$lime-a100: #f4ff81;
$lime-a200: #eeff41;
$lime-a400: #c6ff00;
$lime-a700: #aeea00;
$yellow-c50: #fffde7;
$yellow-c100: #fff9c4;
$yellow-c200: #fff59d;
$yellow-c300: #fff176;
$yellow-c400: #ffee58;
$yellow-c500: #ffeb3b;
$yellow-c600: #fdd835;
$yellow-c700: #fbc02d;
$yellow-c800: #f9a825;
$yellow-c900: #f57f17;
$yellow-a100: #ffff8d;
$yellow-a200: #ffff00;
$yellow-a400: #ffea00;
$yellow-a700: #ffd600;
$amber-c50: #fff8e1;
$amber-c100: #ffecb3;
$amber-c200: #ffe082;
$amber-c300: #ffd54f;
$amber-c400: #ffca28;
$amber-c500: #ffc107;
$amber-c600: #ffb300;
$amber-c700: #ffa000;
$amber-c800: #ff8f00;
$amber-c900: #ff6f00;
$amber-a100: #ffe57f;
$amber-a200: #ffd740;
$amber-a400: #ffc400;
$amber-a700: #ffab00;
$orange-c50: #fff3e0;
$orange-c100: #ffe0b2;
$orange-c200: #ffcc80;
$orange-c300: #ffb74d;
$orange-c400: #ffa726;
$orange-c500: #ff9800;
$orange-c600: #fb8c00;
$orange-c700: #f57c00;
$orange-c800: #ef6c00;
$orange-c900: #e65100;
$orange-a100: #ffd180;
$orange-a200: #ffab40;
$orange-a400: #ff9100;
$orange-a700: #ff6d00;
$deepOrange-c50: #fbe9e7;
$deepOrange-c100: #ffccbc;
$deepOrange-c200: #ffab91;
$deepOrange-c300: #ff8a65;
$deepOrange-c400: #ff7043;
$deepOrange-c500: #ff5722;
$deepOrange-c600: #f4511e;
$deepOrange-c700: #e64a19;
$deepOrange-c800: #d84315;
$deepOrange-c900: #bf360c;
$deepOrange-a100: #ff9e80;
$deepOrange-a200: #ff6e40;
$deepOrange-a400: #ff3d00;
$deepOrange-a700: #dd2c00;
$brown-c50: #efebe9;
$brown-c100: #d7ccc8;
$brown-c200: #bcaaa4;
$brown-c300: #a1887f;
$brown-c400: #8d6e63;
$brown-c500: #795548;
$brown-c600: #6d4c41;
$brown-c700: #5d4037;
$brown-c800: #4e342e;
$brown-c900: #3e2723;
$grey-c50: #fafafa;
$grey-c100: #f5f5f5;
$grey-c200: #eeeeee;
$grey-c300: #e0e0e0;
$grey-c400: #bdbdbd;
$grey-c500: #9e9e9e;
$grey-c600: #757575;
$grey-c700: #616161;
$grey-c800: #424242;
$grey-c900: #212121;
$blueGrey-c50: #eceff1;
$blueGrey-c100: #cfd8dc;
$blueGrey-c200: #b0bec5;
$blueGrey-c300: #90a4ae;
$blueGrey-c400: #78909c;
$blueGrey-c500: #607d8b;
$blueGrey-c600: #546e7a;
$blueGrey-c700: #455a64;
$blueGrey-c800: #37474f;
$blueGrey-c900: #263238;