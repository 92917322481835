/* https://www.npmjs.com/package/react-splitter-layout */

@import '../Colors.scss';
@import '../Variables.scss';

.splitter-layout {
   position: absolute;
   left: $sidebar-inactive-width;
   display: flex;
   flex-direction: row;
   width: calc(100% - #{$sidebar-inactive-width});
   height: 100%;
   overflow: hidden;
}

.splitter-layout .layout-pane {
    position: relative;
    flex: 0 0 auto;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.splitter-layout .layout-pane.layout-pane-primary {
    flex: 1 1 auto;
}

.splitter-layout > .layout-splitter {
    flex: 0 0 auto;
    width: 4px;
    height: 100%;
    cursor: col-resize;
    background-color: $grey-c400;
}

.splitter-layout .layout-splitter:hover {
    background-color: $amber-c500;
}

.splitter-layout.layout-changing {
    cursor: col-resize;
}

.splitter-layout.layout-changing > .layout-splitter {
    background-color: $amber-c500;
}

.splitter-layout.splitter-layout-vertical {
    flex-direction: column;
}

.splitter-layout.splitter-layout-vertical.layout-changing {
    cursor: row-resize;
}

.splitter-layout.splitter-layout-vertical > .layout-splitter {
    width: 100%;
    height: 4px;
    cursor: row-resize;
}