@import "../Colors.scss";

$callout-background-color:  black;
$callout-border-color:  $grey-c800;

.lighterOnHover:hover{
  opacity: 0.8;
  transition: opacity 0.3s ease;
}

.backStripe{
  fill: $grey-c100;
  transition: fill 0.3s ease;
  opacity: 0.8;
}

.noPointerEvents {
  pointer-events: none;
}

.totalLine {
  stroke: $grey-c800
}

/*** vis actions ***/

.chartMenu {
  position: absolute;
  top: 0;
  right: 0;
}

.actionIcon {
  width: 24px;
  height: 24px;
  cursor: pointer;
  color: $grey-c400;
  transition: color 0.3s ease;
}

.actionIcon:hover {
  color: $amber-c500;
}

/*** call-outs start ***/

.callOut {
  font-size: 12px;
  border: 1px solid $callout-border-color;
  position: absolute;
  padding: 10px;
  border-radius: 6px;
  background: $callout-background-color;
  color: white;
  line-height: 18px;
  transition: top 0.3s ease, left 0.3s ease, opacity 0.3s ease;
  pointer-events: none;
  min-width: 30px;

  b {
    font-weight: 500;
    color: $amber-c100;
  }
}

.callOut:before {
  content: '';
  position: absolute;
}

.callOut:after {
  position: absolute;
  content: '';
}

.callOut.top:before {
  top: -12px;
  left: 20px;
  border-top: 0px solid transparent;
  border-left: 10px solid transparent;
  border-bottom: 10px solid $callout-background-color;
  border-right: 10px solid transparent;
}

.callOut.top:after {
  top: -10px;
  left: 20px;
  border-top: 0px solid transparent;
  border-left: 10px solid transparent;
  border-bottom: 10px solid $callout-border-color;
  border-right: 10px solid transparent;
}

.callOut.bottom:before {
  bottom: -11px;
  left: 20px;
  border-top: 10px solid $callout-border-color;
  border-left: 10px solid transparent;
  border-bottom: 0px solid transparent;
  border-right: 10px solid transparent;
}

.callOut.bottom:after {
  bottom: -10px;
  left: 20px;
  border-top: 10px solid $callout-background-color;
  border-left: 10px solid transparent;
  border-bottom: 0px solid transparent;
  border-right: 10px solid transparent;
}

.callOut.left:before {
  left: -11px;
  border-top: 10px solid transparent;
  border-left: 0px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid $callout-background-color;
}

.callOut.left:after {
  left: -10px;
  border-top: 10px solid transparent;
  border-left: 0px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid $callout-border-color;
}

.callOut.right:before {
  right: -11px;
  border-top: 10px solid transparent;
  border-left: 10px solid $callout-background-color;
  border-bottom: 10px solid transparent;
  border-right: 0px solid transparent;
}

.callOut.right:after {
  right: -10px;
  border-top: 10px solid transparent;
  border-left: 10px solid $callout-border-color;
  border-bottom: 10px solid transparent;
  border-right: 0px solid transparent;
}

/*** call-outs end ***/

.chartGridTitleCell {
  text-align: center;
  font-size: 1.2em;
  font-weight: 500;
}

.chartGridCellRotate {
  transform: rotate(-90deg);
}

.chartGridTable {
  margin: 1.5em auto;
}

.chartGridCell {
  text-align: center;
  vertical-align: top;
}

.visContainer {
  position: relative;
}

.controlPanel {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  border: 1px solid $grey-c300;
  background-color: $grey-c100;
}

.title {
  text-align: center;
  margin-top: 32px;
  margin-bottom: 8px;
  padding: 0 32px;
  font-weight: 500;
}

.footer {
  text-align: center;
  margin-top: 16px;
  font-weight: 400;
}

.chartContainer {
  position: relative
}