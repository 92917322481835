@import './Colors.scss';

$column-divider-width:          3px;
$table-header-cell-y-padding:   16px;
$table-header-height:           48px;
$cell-x-padding:                12px;
$table-body-cell-y-padding:     16px;
$table-grid-width:              1px;
$column-divider-color:          $grey-c300;
$table-grid-color:              $grey-c300;
$header-back-color:             $grey-c100;
$pinned-column-back-color:      $grey-c100;
$selected-column-back-color:    $amber-c50;
$hovered-column-back-color:     $yellow-c50;
$hover-sel-column-back-color:   $orange-c50;
$scrollbar-back-color:          $grey-c300;
$scrollbar-thumb-back-color:    $grey-c600;
$col-ctrl-icon-off-color:       $grey-c600;
$col-ctrl-icon-on-color:        $amber-c500;
$col-ctrl-icon-hover-color:     black;
$col-ctrl-icon-disabled-color:  $grey-c300;
$table-body-font-family:        'Source Code Pro';
$table-body-font-size:          14px;
$sidebar-inactive-width:        50px;
$sidebar-active-width:          70px;