@import '../Colors.scss';
@import '../Variables.scss';

.error {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -5em;
  margin-left: -5em;
  img {
    width: 10em;
  }
}

.mainSplitter {
  overflow: hidden;
}
.mainSplitter .layout-pane {
  overflow: hidden;
}

.tabbedPanels {
  position: fixed;
  left: $sidebar-active-width;
  display: flex;
  flex-direction: column;
  width: calc(100% - #{$sidebar-active-width});
  height: 100%;
  overflow: hidden;

  .navigation {
    display: flex;
    flex: 0 0 auto;
    flex-direction: row;
  }

  .content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;
  }
}

