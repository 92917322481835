@import "../Colors.scss";

$scrollbar-back-color:          $grey-c300;
$scrollbar-thumb-back-color:    $grey-c600;

.body {
    font-family: 'Ubuntu Mono', monospace;
    font-weight: 400;
    height: 100%;
    overflow: hidden;
    position: relative;
}
.content {
    position: relative;
    touch-action: none;
}
.scrollBar {
    position: absolute;
    width: 9px;
    right: 2px;
    bottom: 2px;
    top: 2px;
    border-radius: 5px;
    opacity: 0.5;
    touch-action: none;
}
.scrollBar:hover {
    background-color: $scrollbar-back-color;
    .scrollThumb {
        background-color: $scrollbar-thumb-back-color;
    }
}
.scrollingBar {
    background-color: $scrollbar-back-color;
    .scrollThumb {
        background-color: $scrollbar-thumb-back-color;
    }
}
.scrollThumb {
    position: relative;
    display: block;
    width: 100%;
    cursor: pointer;
    border-radius: inherit;
    background-color: $grey-c400;
}
.nonSelectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}