@import '../Colors.scss';
@import '../Variables.scss';

.container {
  position: absolute;
  width: $sidebar-inactive-width;
  border-right: 3px $grey-c300 solid;
  height: 100%;
  background-color: $grey-c100;
  display: flex;
  flex-direction: column;
  transition: width 0.3s ease;
}

.container.active {
  width: $sidebar-active-width;
}

.logo {
  margin: 0 auto;
  width: 32px;
  height: $table-header-height;
  transition: opacity 1.0s ease;
  opacity: 0.4;
}

.logo:hover {
  opacity: 1.0;
  transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
  transform-origin: center;
  -moz-transform-origin: center;
  -webkit-transform-origin: center;
  transition:  1.5s ease all;
  -moz-transition: 1.5s ease all;
  -webkit-transition:  1.5s ease all;
}

.iconBox {
  min-height: $table-body-cell-y-padding * 2 + $table-body-font-size + $table-grid-width;
  margin: 0 0 0 12px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}

.icon {
  color: $grey-c500;
  cursor: pointer;
  transition: color 0.3s ease;
  height: 24px;
}

.icon:hover {
  color: $amber-c500;
}

.icon.disabled {
  opacity: 0.3;
  cursor: inherit;
}

.icon.disabled:hover {
  color: $grey-c500;
  opacity: 0.3;
}

.dropiconContainer {
  transition: opacity 0.2s ease-out;
  opacity: 0;
}

.container.active .dropiconContainer {
  opacity: 1.0;
  transition: opacity 0.5s ease-in;
}

.dropicon {
  color: $grey-c500;
  cursor: pointer;
  height: 14px;
  margin-left: 8px;
  transition: color 0.3s ease;
}

.dropicon:hover {
  color: $amber-c500;
}

.dropicon.disabled {
  opacity: 0.3;
  cursor: inherit;
}

.dropicon.disabled:hover {
  color: $grey-c500;
  opacity: 0.3;
}
